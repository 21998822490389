<template>
  <div class="tinymce-box">
    <editor
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onClick="onClick"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import tinymce from 'tinymce/tinymce' // tinymce默认hidden，不引入不显示
import Editor from '@tinymce/tinymce-vue'
import 'tinymce/themes/silver'
import 'tinymce/icons/default/icons'
// 编辑器插件plugins
// 更多插件参考：https://www.tiny.cloud/docs/plugins/
import 'tinymce/plugins/image' // 插入上传图片插件
import 'tinymce/plugins/media' // 插入视频插件
import 'tinymce/plugins/table' // 插入表格插件
import 'tinymce/plugins/lists' // 列表插件
import 'tinymce/plugins/wordcount' // 字数统计插件
export default {
  name: 'Tinymce',
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    plugins: {
      type: [String, Array],
      default: 'lists image media table wordcount'
    },
    toolbar: {
      type: [String, Array],
      default:
        'bold italic forecolor backcolor fontsizeselect code lineheight | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table'
    },
    height: {
      type: String,
      default: '300'
    }
  },
  data() {
    return {
      init: {
        language_url: 'tinymce/langs/zh_CN.js',
        language: 'zh_CN',
        skin_url: 'tinymce/skins/ui/oxide',
        // skin_url: 'tinymce/skins/ui/oxide-dark',//暗色系
        height: this.height,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        menubar: false,
        // 此处为图片上传处理函数，这个直接用了base64的图片形式上传图片，
        // 如需ajax上传可参考https://www.tiny.cloud/docs/configure/file-image-upload/#images_upload_handler
        images_upload_handler: (blobInfo, success, failure) => {
          const isLt500K = blobInfo.blob().size / 1024 / 1024 > 0.5
          if (isLt500K) {
            failure('上传图片的大小不能大于500k')
            return false
          }
          var xhr, formData
          xhr = new XMLHttpRequest()
          xhr.withCredentials = false
          xhr.open('POST', process.env.VUE_APP_QINIUPHOST)
          xhr.onload = () => {
            var json = JSON.parse(xhr.responseText)
            const img = this.qiniuData.host + json.key
            success(img)
          }

          formData = new FormData()
          formData.append('file', blobInfo.blob(), blobInfo.filename())
          formData.append('token', this.qiniuData.token)
          xhr.send(formData)
        }
      },
      myValue: this.value
    }
  },
  computed: {
    ...mapState(['qiniuData'])
  },
  components: {
    Editor
  },
  mounted() {
    // tinymce.init({})
  },
  methods: {
    // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    // 需要什么事件可以自己增加
    onClick(e) {
      this.$emit('onClick', e, tinymce)
    },
    // 可以添加一些自己的自定义事件，如清空内容
    clear() {
      this.myValue = ''
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue
    },
    myValue(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
<style scoped></style>
